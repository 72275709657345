import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import Button from "~components/molecules/button/button/button";
import StickySideNavWrapper from "~components/utils/sticky_side_nav_wrapper/sticky_side_nav_wrapper";
import { LINK } from "../../../../prop_types";
import JobPostInfo from "../job_post_info/job_post_info";
import JobPostSocialSharePopup from "../job_post_social_share_popup/job_post_social_share_popup";
import {
  contentCtaContainerStyle,
  contentSidePanelStyle,
} from "./job_post_side_panel.module.scss";

export default function JobPostSidePanel({
  applicationPageLink,
  officeLocationCountry,
  officeLocationCity,
  salaryRange,
  teamName,
  title,
  jobPostUrl,
  isRoleRemote,
}) {
  return (
    <StickySideNavWrapper>
      <div className={contentSidePanelStyle}>
        <JobPostInfo
          {...{
            officeLocationCountry,
            officeLocationCity,
            salaryRange,
            teamName,
            isRoleRemote,
          }}
        />
        <div
          className={contentCtaContainerStyle}
          data-testid="ch-job-post-cta-and-social-share-container"
        >
          <Heading
            balanceText
            isCentered
            visualLevel={6}
            seoLevel={5}
            isExtraBold
          >
            Sound like the role for you?
          </Heading>
          <Button
            text="Apply now"
            isUppercase
            color="yellow"
            isFullWidth
            link={applicationPageLink}
          />

          <JobPostSocialSharePopup
            title={`TrueLayer | ${title} opportunity`}
            jobPostUrl={jobPostUrl}
          />
        </div>
      </div>
    </StickySideNavWrapper>
  );
}

JobPostSidePanel.propTypes = {
  applicationPageLink: LINK.isRequired,
  jobPostUrl: PropTypes.string.isRequired,
  officeLocationCity: PropTypes.string.isRequired,
  officeLocationCountry: PropTypes.string.isRequired,
  salaryRange: PropTypes.shape({
    unit: PropTypes.string,
    min_value: PropTypes.string,
    max_value: PropTypes.string,
  }),
  teamName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isRoleRemote: PropTypes.bool,
};

JobPostSidePanel.defaultProps = {
  salaryRange: null,
  isRoleRemote: false,
};
