import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import SVGFlagIcon from "~components/atoms/svg_flag_icon/svg_flag_icon";
import RscHbCardInner from "~components/molecules/rsc_hb_card/rsc_hb_card_inner/rsc_hb_card_inner";
import RscHbCardWrapper from "~components/molecules/rsc_hb_card/rsc_hub_card_wrapper/rsc_hub_card_wrapper";
import TextLink from "~components/molecules/text_link/text_link";
import countryToIsoCode from "~utils/country_utils/country_to_iso_code";
import { LINK } from "../../../../../../prop_types";
import {
  locationContainerStyle,
  locationTextStyle,
} from "./related_jobs_card.module.scss";

const RelatedJobCard = ({ title, teamName, country, officeLocation, link }) => {
  const [isActive, setIsActive] = useState(false);
  const countryCode = useMemo(() => {
    return countryToIsoCode(country);
  }, [country]);
  return (
    <RscHbCardWrapper
      hasCard
      link={link}
      isActive={isActive}
      setIsActive={setIsActive}
      data-testid="ch-job-post-related-job-card"
    >
      <RscHbCardInner
        addPadding
        fullWidth
        title={`${title} (${teamName})`}
        large={false}
      >
        <div className={locationContainerStyle}>
          <SVGFlagIcon
            small
            marginRight
            countryCode={countryCode}
            altText={`${country} flag`}
          />
          <p className={locationTextStyle}>{`${officeLocation}, ${country}`}</p>
        </div>
        <TextLink title="See role" isActive={isActive} />
      </RscHbCardInner>
    </RscHbCardWrapper>
  );
};

RelatedJobCard.propTypes = {
  title: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  officeLocation: PropTypes.string.isRequired,
  link: LINK.isRequired,
};

export default RelatedJobCard;
