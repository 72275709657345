import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import Button from "~components/molecules/button/button/button";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";
import { LINK } from "../../../../prop_types";
import JobPostInfo from "../job_post_info/job_post_info";
import {
  heroButtonsStyle,
  heroFlexWrapperStyle,
  heroLeftStyle,
  jobPostInfoWrapperStyle,
} from "./job_post_hero_section.module.scss";

const HEADING_MARGIN = { marginBottom: "md" };

function JobPostHeroSection({
  applicationPageLink,
  title,
  officeLocationCity,
  officeLocationCountry,
  salaryRange,
  teamName,
  isRoleRemote,
}) {
  return (
    <div className={heroFlexWrapperStyle}>
      <div className={heroLeftStyle}>
        <Heading margin={HEADING_MARGIN} visualLevel={2} seoLevel={1}>
          {title}
        </Heading>
        <div className={heroButtonsStyle}>
          <Button
            text="Apply now"
            isUppercase
            color="yellow"
            link={applicationPageLink}
          />
          <Button
            leadingIcon="icon_chevron_left"
            text="Back to open roles"
            isUppercase
            color="yellowTransparent"
            link={createInternalLink("/jobs")}
          />
        </div>

        <div className={jobPostInfoWrapperStyle}>
          <JobPostInfo
            {...{
              officeLocationCity,
              officeLocationCountry,
              salaryRange,
              teamName,
              isRoleRemote,
            }}
          />
        </div>
      </div>
    </div>
  );
}

JobPostHeroSection.propTypes = {
  applicationPageLink: LINK.isRequired,
  officeLocationCity: PropTypes.string.isRequired,
  officeLocationCountry: PropTypes.string.isRequired,
  salaryRange: PropTypes.shape({
    unit: PropTypes.string,
    min_value: PropTypes.string,
    max_value: PropTypes.string,
  }),
  teamName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isRoleRemote: PropTypes.bool,
};

JobPostHeroSection.defaultProps = {
  salaryRange: null,
  isRoleRemote: false,
};

export default JobPostHeroSection;
