import React, { useContext } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import HideOnScrollContext from "../../../hide_on_scroll_context";
import {
  headingPaddingStyle,
  menuStickyWrapperStyle,
  menuWrapperStyle,
} from "./sticky_side_nav_wrapper.module.scss";

function StickySideNavWrapper({ children }) {
  const { shouldHideHeader } = useContext(HideOnScrollContext);

  const menuStickyWrapperClassNames = classNames(menuStickyWrapperStyle, {
    [headingPaddingStyle]: shouldHideHeader === false,
  });

  return (
    <div className={menuWrapperStyle}>
      <div className={menuStickyWrapperClassNames}>{children}</div>
    </div>
  );
}

StickySideNavWrapper.defaultProps = {
  children: null,
};

StickySideNavWrapper.propTypes = {
  children: PropTypes.node,
};

export default StickySideNavWrapper;
