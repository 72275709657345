import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import getRelatedJobsSubheader from "./helper_function/get_related_job_subheader";
import { subheaderStyle } from "./related_jobs_header.module.scss";

const RELATED_JOBS_HEADER = "Not quite the right fit?";
const HEADING_MARGIN = { marginBottom: "none" };

const RelatedJobsHeader = ({ numberOfJobs }) => {
  const subheaderText = getRelatedJobsSubheader(numberOfJobs);

  return (
    <>
      <Heading visualLevel={2} seoLevel={4} margin={HEADING_MARGIN}>
        {RELATED_JOBS_HEADER}
      </Heading>
      <p className={subheaderStyle}>{subheaderText}</p>
    </>
  );
};

RelatedJobsHeader.propTypes = {
  numberOfJobs: PropTypes.number.isRequired,
};

export default RelatedJobsHeader;
