import React from "react";
import PropTypes from "prop-types";
import GridNew from "~components/atoms/grid_new/grid_new";
import { LINK } from "../../../../../../prop_types";
import RelatedJobsCard from "../related_jobs_card/related_jobs_card";
import getGridSplitByAvailableJobs from "./helper_function/get_grid_split_by_available_jobs";
import { rolesContainerStyle } from "./related_jobs_card_list.module.scss";

const RelatedJobsCardList = ({ relatedJobsList }) => {
  const numberOfJobs = relatedJobsList.length;
  const gridSplit = getGridSplitByAvailableJobs({
    length: numberOfJobs,
  });

  if (numberOfJobs > 0) {
    return (
      <div className={rolesContainerStyle}>
        <GridNew split={gridSplit}>
          {relatedJobsList.map((job) => {
            return (
              <RelatedJobsCard
                key={job.title}
                title={job.title}
                teamName={job.teamName}
                country={job.country}
                officeLocation={job.officeLocation}
                link={job.link}
              />
            );
          })}
        </GridNew>
      </div>
    );
  }

  return null;
};

RelatedJobsCardList.propTypes = {
  relatedJobsList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      teamName: PropTypes.string,
      country: PropTypes.string,
      officeLocation: PropTypes.string,
      link: LINK,
    })
  ).isRequired,
};

export default RelatedJobsCardList;
