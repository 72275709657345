import React from "react";
import PropTypes from "prop-types";
import {
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import Contain from "~components/atoms/contain/contain";
// import Heading from "~components/atoms/heading/heading";
import Button from "~components/molecules/button/button/button";
import StickyNav from "~components/molecules/sticky_nav/sticky_nav";
import { LINK } from "../../../../prop_types";
import JobPostCopyLinkButton from "../job_post_social_share_popup/sub_components/job_post_copy_link_button/job_post_copy_link_button";
import {
  buttonWrapperStyle,
  socialButtonStyle,
} from "./job_post_sticky_apply_button.module.scss";

const STYLES = {
  backgroundColor: "transparent",
  fill: "transparent",
};

function JobPostStickyApplyButton({ applicationPageLink, jobPostUrl, title }) {
  return (
    <StickyNav shouldUseBorder={false}>
      <Contain>
        <div className={buttonWrapperStyle}>
          <Button
            text="Apply now"
            isUppercase
            color="yellow"
            isFullWidth
            link={applicationPageLink}
          />

          <JobPostCopyLinkButton url={jobPostUrl} />
          <LinkedinShareButton
            url={jobPostUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className={socialButtonStyle}
            data-tip="Share on LinkedIn"
          >
            <LinkedinIcon
              bgStyle={STYLES}
              borderRadius={5}
              iconFillColor="#f7ab1b"
              size={32}
            />
          </LinkedinShareButton>
          <TwitterShareButton
            url={jobPostUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className={socialButtonStyle}
            data-tip="Share on Twitter"
          >
            <TwitterIcon
              bgStyle={STYLES}
              borderRadius={5}
              iconFillColor="#f7ab1b"
              size={32}
            />
          </TwitterShareButton>
        </div>
      </Contain>
    </StickyNav>
  );
}

JobPostStickyApplyButton.propTypes = {
  applicationPageLink: LINK,
  jobPostUrl: PropTypes.string,
  title: PropTypes.string,
};

JobPostStickyApplyButton.defaultProps = {
  jobPostUrl: null,
  applicationPageLink: null,
  title: null,
};

export default JobPostStickyApplyButton;
