import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "~components/atoms/tooltip/tooltip";
import Button from "~components/molecules/button/button/button";
import SVGIcon from "~components/utils/svg_icon/svg_icon";
import copyLinkToClipboard from "~utils/copy_link_to_clipboard/copy_link_to_clipboard";

const JobPostCopyLinkButton = ({ url }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const resetTooltip = useCallback(() => {
    setCopiedToClipboard(false);
  }, []);

  const handleCopyToClipboard = useCallback(() => {
    copyLinkToClipboard(url);
    setCopiedToClipboard(true);

    setTimeout(() => {
      resetTooltip();
    }, 1000);
  }, [resetTooltip, url]);

  const tooltipText = copiedToClipboard ? "Copied" : "Copy link";

  return (
    <Tooltip content={tooltipText} minWidth={120} position="bottom">
      <Button
        color="yellowTransparent"
        onClick={handleCopyToClipboard}
        size="square"
        isFullWidth
      >
        <SVGIcon color="sun" width={16} height={16} icon="icon_link" />
      </Button>
    </Tooltip>
  );
};

JobPostCopyLinkButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export default JobPostCopyLinkButton;
