import { formatCurrencyString } from "~utils/format_currency/format_currency";

// Todo(BSE): Write unit tests for this and lower order functions

export default function getFormattedSalaryRangeString({
  countryCode,
  salaryRange,
}) {
  if (salaryRange) {
    const {
      max_value: maxSalaryValue,
      min_value: minSalaryValue,
      unit: unitCode,
    } = salaryRange;

    const formattedMinSalary = formatCurrencyString({
      countryCode,
      currencyString: minSalaryValue,
      unitCode,
    });
    const formattedMaxSalary = formatCurrencyString({
      countryCode,
      currencyString: maxSalaryValue,
      unitCode,
    });

    return `${formattedMinSalary} — ${formattedMaxSalary}`;
  }
  return null;
}
