import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Button from "~components/molecules/button/button/button";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";
import { LINK } from "../../../../prop_types";
import RelatedJobsCardList from "./sub_components/related_jobs_card_list/related_jobs_card_list";
import RelatedJobsHeader from "./sub_components/related_jobs_header/related_jobs_header";

const JobPostRelatedJobsSection = ({ relatedJobsList }) => {
  const numberOfJobs = relatedJobsList.length;
  return (
    <Contain isContentCentered>
      <RelatedJobsHeader numberOfJobs={numberOfJobs} />
      <RelatedJobsCardList relatedJobsList={relatedJobsList} />
      <Button
        text="View open roles"
        isUppercase
        color="yellowTransparent"
        link={createInternalLink("/jobs")}
        marginTop={24}
      />
    </Contain>
  );
};

JobPostRelatedJobsSection.propTypes = {
  relatedJobsList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      teamName: PropTypes.string,
      country: PropTypes.string,
      officeLocation: PropTypes.string,
      link: LINK,
    })
  ).isRequired,
};

export default JobPostRelatedJobsSection;
