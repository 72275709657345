import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import TransitionComponent from "~components/utils/transition/transition";

import * as classes from "./tooltip.module.scss";

const Tooltip = ({ children, position, delay, content, minWidth, block }) => {
  let timeout;
  const [isActive, setIsActive] = useState(false);

  const [localPosition, setLocalPosition] = useState(position);

  const ref = useRef(null);

  const classNames = [classes.tooltip, classes[localPosition]];
  const wrapperClassNames = [classes.wrapper];

  if (block) {
    wrapperClassNames.push(classes.block);
  }

  const [style, setStyle] = useState(
    minWidth
      ? {
          minWidth: `${minWidth}px`,
          whiteSpace: "normal",
        }
      : {}
  );
  const [arrowStyle, setArrowStyle] = useState({});

  const showTip = () => {
    timeout = setTimeout(() => {
      setIsActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setIsActive(false);
  };

  useEffect(() => {
    if (isActive && ref.current !== null) {
      const tooltip = ref.current.getBoundingClientRect();

      let tooltipX = null;

      if (tooltip.x + tooltip.width > window.innerWidth) {
        if (localPosition === "right") {
          setLocalPosition("left");
        }
        if (localPosition === "bottom") {
          setLocalPosition("left");
        } else {
          tooltipX = -tooltip.width + 16;
          setStyle({
            ...style,
            transform: `translateX(${tooltipX}px) translateY(-8px)`,
          });
          if (localPosition === "top") {
            setArrowStyle({
              ...arrowStyle,
              transform: `translateX(${
                Math.abs(tooltipX / 2) - 8
              }px) translateY(8px)`,
            });
          } else {
            setArrowStyle({
              ...arrowStyle,
              transform: `translateX(${Math.abs(tooltipX / 2) - 8}px) `,
            });
          }
        }
      }
    }
  }, [isActive, arrowStyle, localPosition, style]);

  return (
    <div
      className={wrapperClassNames.join(" ")}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      <TransitionComponent unmountOnExit isActive={isActive} appear>
        <div ref={ref} className={classNames.join(" ")} style={style}>
          {content}
          <div className={classes.chevron} style={arrowStyle} />
        </div>
      </TransitionComponent>
    </div>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.string,
  position: PropTypes.string,
  delay: PropTypes.number,
  minWidth: PropTypes.number,
  block: PropTypes.bool,
};

Tooltip.defaultProps = {
  children: null,
  content: null,
  position: "top",
  delay: 0,
  minWidth: null,
  block: false,
};
