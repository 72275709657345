import React from "react";
import PropTypes from "prop-types";
import {
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import Heading from "~components/atoms/heading/heading";
import {
  socialButtonStyle,
  socialShareButtonsWrapperStyle,
  wrapperStyle,
} from "./job_post_social_share_popup.module.scss";
import JobPostCopyLinkButton from "./sub_components/job_post_copy_link_button/job_post_copy_link_button";

const STYLES = {
  backgroundColor: "transparent",
  fill: "transparent",
};

export default function JobPostSocialSharePopup({ title, jobPostUrl }) {
  return (
    <div className={wrapperStyle}>
      <Heading color="gray" isCentered isUppercase visualLevel={7} seoLevel={5}>
        Share this role via
      </Heading>
      <div className={socialShareButtonsWrapperStyle}>
        <JobPostCopyLinkButton url={jobPostUrl} />
        <LinkedinShareButton
          url={jobPostUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className={socialButtonStyle}
          data-tip="Share on LinkedIn"
        >
          <LinkedinIcon
            bgStyle={STYLES}
            borderRadius={5}
            iconFillColor="#f7ab1b"
            size={32}
          />
        </LinkedinShareButton>
        <TwitterShareButton
          url={jobPostUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className={socialButtonStyle}
          data-tip="Share on Twitter"
        >
          <TwitterIcon
            bgStyle={STYLES}
            borderRadius={5}
            iconFillColor="#f7ab1b"
            size={32}
          />
        </TwitterShareButton>
      </div>
    </div>
  );
}

JobPostSocialSharePopup.defaultProps = {
  title: "TrueLayer | Open roles",
  jobPostUrl: "https://truelayer.com/jobs",
};

JobPostSocialSharePopup.propTypes = {
  title: PropTypes.string,
  jobPostUrl: PropTypes.string,
};
