import React, { useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import HideOnScrollContext from "../../../hide_on_scroll_context";
import { BLOK_ARRAY } from "../../../prop_types";
import {
  borderStyle,
  dropShadowStyle,
  headingPaddingStyle,
  offsetTopStyle,
  stickyNavInnerStyle,
  stickyNavWrapperStyle,
} from "./sticky_nav.module.scss";

export default function StickyNav({
  children,
  shouldOffsetTop,
  shouldUseBorder,
}) {
  const { isScrolledToTop, shouldHideHeader } = useContext(HideOnScrollContext);

  const stickyNavWrapperClassNames = classNames(stickyNavWrapperStyle, {
    [headingPaddingStyle]: shouldHideHeader === false,
    [dropShadowStyle]: !isScrolledToTop,
    [offsetTopStyle]: shouldOffsetTop,
  });
  const stickyNavInnerClassNames = classNames(stickyNavInnerStyle, {
    [borderStyle]: shouldUseBorder && isScrolledToTop,
  });

  return (
    <div className={stickyNavWrapperClassNames}>
      <Contain>
        <div className={stickyNavInnerClassNames}>{children}</div>
      </Contain>
    </div>
  );
}

StickyNav.defaultProps = {
  children: null,
  shouldOffsetTop: false,
  shouldUseBorder: true,
};

StickyNav.propTypes = {
  children: PropTypes.oneOfType([BLOK_ARRAY, PropTypes.node]),
  shouldOffsetTop: PropTypes.bool,
  shouldUseBorder: PropTypes.bool,
};
