/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable react/no-danger */
import React, { useMemo } from "react";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";

const SanitizeHtmlWrapper = ({ wrapperClassName, htmlString, ...rest }) => {
  const sanitizedHtml = useMemo(() => {
    if (typeof window !== "undefined") {
      const parsedHtml = new DOMParser().parseFromString(
        htmlString,
        "text/html"
      );
      return DOMPurify.sanitize(parsedHtml.documentElement.textContent, {
        SANITIZE_DOM: false,
      });
    }
    return null;
  }, [htmlString]);

  if (sanitizedHtml) {
    return (
      <div
        className={wrapperClassName}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        {...rest}
      />
    );
  }

  return null;
};

SanitizeHtmlWrapper.propTypes = {
  htmlString: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
};

SanitizeHtmlWrapper.defaultProps = {
  wrapperClassName: "",
};

export default SanitizeHtmlWrapper;
