import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import Image from "~components/atoms/image/image";
import Button from "~components/molecules/button/button/button";
import RichText from "~components/molecules/rich_text/rich_text";
import { LINK, MEDIA } from "../../../../prop_types";
import {
  headingWrapper,
  imageGridWrapper,
  outerWrapperStyle,
} from "./job_post_localised_content.module.scss";

const GRID_SPLIT = { XXL: "2", S: "1" };
const LINK_TEXT = "Apply for this role";

function JobPostLocalisedContent({
  title,
  text: textArray,
  photo1,
  photo2,
  photo3,
  link,
}) {
  const [text] = textArray;
  return (
    <div className={outerWrapperStyle}>
      <Contain>
        <GridNew split={GRID_SPLIT} hasColumnGap hasRowGap>
          <div className={headingWrapper}>
            <Heading visualLevel={3} seoLevel={3}>
              {title}
            </Heading>
            <RichText {...text} />
            <Button text={LINK_TEXT} link={link} />
          </div>
          <div className={imageGridWrapper}>
            <Image image={photo1} />
            <Image image={photo2} />
            <Image image={photo3} />
          </div>
        </GridNew>
      </Contain>
    </div>
  );
}

JobPostLocalisedContent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.shape({})),
  photo1: MEDIA,
  photo2: MEDIA,
  photo3: MEDIA,
  link: LINK,
};

JobPostLocalisedContent.defaultProps = {
  title: null,
  text: null,
  photo1: null,
  photo2: null,
  photo3: null,
  link: null,
};

export default JobPostLocalisedContent;
