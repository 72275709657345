export default function getRelatedJobsSubheader(numberOfJobs) {
  let subheaderText = "Check out our open roles.";

  if (numberOfJobs === 1) {
    subheaderText = "This job might be a bit more you.";
  } else if (numberOfJobs > 1) {
    subheaderText = "These jobs might be a bit more you.";
  }

  return subheaderText;
}
