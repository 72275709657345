import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import SVGIcon from "~components/utils/svg_icon/svg_icon";

import {
  infoContainerStyle,
  wrapperStyle,
} from "./meta_info_wrapper.module.scss";

const MetaInfoWrapper = ({
  children,
  icon,
  iconColor,
  iconSize,
  infoType,
  wrapperClassName,
  infoContainerClassName,
}) => {
  const wrapperDynamicClassName = classNames(wrapperStyle, wrapperClassName);
  const infoContainerDynamicClassName = classNames(
    infoContainerStyle,
    infoContainerClassName
  );

  return (
    <div data-testid={infoType} className={wrapperDynamicClassName}>
      <SVGIcon
        color={iconColor}
        height={iconSize}
        icon={icon}
        width={iconSize}
      />
      <div className={infoContainerDynamicClassName}>{children}</div>
    </div>
  );
};

MetaInfoWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  infoType: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  infoContainerClassName: PropTypes.string,
};

MetaInfoWrapper.defaultProps = {
  icon: null,
  iconColor: "azure",
  iconSize: 20,
  wrapperClassName: "",
  infoContainerClassName: "",
};

export default MetaInfoWrapper;
