import React from "react";
import PropTypes from "prop-types";
import countryToIsoCode from "~utils/country_utils/country_to_iso_code";
import getFormattedSalaryRangeString from "../../helper_functions/get_formatted_salary_range_string/get_formatted_salary_range_string";
import { jobPostInfoWrapperStyle } from "./job_post_info.module.scss";
import JobPostInfoItem from "./sub_components/job_post_info_item/job_post_info_item";

export default function JobPostInfo({
  salaryRange,
  officeLocationCity,
  officeLocationCountry,
  teamName,
  isRoleRemote,
}) {
  const countryCode = countryToIsoCode(officeLocationCountry);
  const salaryRangeString = getFormattedSalaryRangeString({
    countryCode,
    salaryRange,
  });

  const officeLocationString = `${officeLocationCity}, ${officeLocationCountry}`;
  const isRemoteString = isRoleRemote === false ? "On-site" : "Remote";

  return (
    <div className={jobPostInfoWrapperStyle} data-testid="job-post-info">
      <JobPostInfoItem
        heading="Office"
        subHeading={officeLocationString}
        icon="icon_global_geographic"
        iconColor="sun"
        iconSize={36}
      />
      <JobPostInfoItem
        heading="Team"
        subHeading={teamName}
        icon="icon_customers"
        iconColor="sun"
        iconSize={36}
      />
      <JobPostInfoItem
        heading="Work from"
        subHeading={isRemoteString}
        icon="icon_global_connected"
        iconColor="sun"
        iconSize={36}
      />
      {salaryRangeString && (
        <JobPostInfoItem
          heading="Salary"
          subHeading={salaryRangeString}
          icon="icon_money"
          iconColor="sun"
          iconSize={36}
        />
      )}
    </div>
  );
}

JobPostInfo.propTypes = {
  officeLocationCity: PropTypes.string.isRequired,
  officeLocationCountry: PropTypes.string.isRequired,
  salaryRange: PropTypes.shape({
    unit: PropTypes.string,
    min_value: PropTypes.string,
    max_value: PropTypes.string,
  }),
  teamName: PropTypes.string.isRequired,
  isRoleRemote: PropTypes.bool,
};

JobPostInfo.defaultProps = {
  salaryRange: null,
  isRoleRemote: false,
};
