export default function copyLinkToClipboard(link) {
  // Below is the most reliable way of checking if the browser a user is using is IE. There are no official docs on this, but this is a widely accepted solution.
  // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;

  if (isIE) {
    window.clipboardData.setData("Text", link);
  }

  navigator.clipboard.writeText(link);
}
