import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import MetaInfoWrapper from "~components/molecules/meta_info/meta_info_wrapper";
import {
  headingStyle,
  infoContainerStyle,
  subHeadingStyle,
} from "./job_post_info_item.module.scss";

const HEADING_MARGIN = { marginBottom: "xs" };

function JobPostInfoItem({ icon, iconColor, iconSize, heading, subHeading }) {
  return (
    <MetaInfoWrapper
      icon={icon}
      iconColor={iconColor}
      iconSize={iconSize}
      infoContainerClassName={infoContainerStyle}
      infoType={heading}
    >
      <div className={headingStyle}>
        <Heading
          visualLevel={7}
          isUppercase
          isBaseFontWeight
          color="gray"
          margin={HEADING_MARGIN}
        >
          {heading}
        </Heading>
      </div>
      <div className={subHeadingStyle}>
        <Heading visualLevel={6} seoLevel={6} noMargin isExtraBold>
          {subHeading}
        </Heading>
      </div>
    </MetaInfoWrapper>
  );
}

JobPostInfoItem.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
};

JobPostInfoItem.defaultProps = {
  icon: null,
  iconColor: "azure",
  iconSize: 20,
  heading: "Job",
  subHeading: "Info",
};

export default JobPostInfoItem;
