import React, { useMemo } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import SVGBackgroundHeroWrapper from "~components/atoms/svg_background_image/svg_background_image";
import CarouselNew from "~components/organisms/carousel/carousel_new";
import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";
import useMatchMedia from "~hooks/use_match_media/use_match_media";
import useSiteUrl from "~hooks/use_site_url/use_site_url";
import {
  LINK,
  MEDIA,
  STORYBLOK_ENTRY,
  STORYBLOK_ENTRY_ARRAY,
} from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import JobPostHeroSection from "./components/job_post_hero_section/job_post_hero_section";
import JobPostLocalisedContent from "./components/job_post_localised_content/job_post_localised_content";
import JobPostRelatedJobsSection from "./components/job_post_related_jobs_section/job_post_related_jobs_section";
import JobPostSidePanel from "./components/job_post_side_panel/job_post_side_panel";
import JobPostStickyApplyButton from "./components/job_post_sticky_apply_button/job_post_sticky_apply_button";
import SanitizeHtmlWrapper from "./components/sanitised_html_wrapper/sanitised_html_wrapper";
import {
  carouselWrapperStyle,
  contentContainerStyle,
  jobDescriptionStyle,
} from "./template_job_post.module.scss";

const CAROUSEL_HEADING = "The TrueLayer Experience";
const HEADING_MARGIN = { marginBottom: "none" };

export default function TemplateJobPost(props) {
  const { data, pageContext } = props;
  const { nodes: entryAssets } = data?.assets || [];
  const {
    content,
    officeLocationCountry,
    officeLocationCity,
    pagePath,
    relatedJobsList,
    localisedContent,
    salaryRange,
    subNavData,
    teamName,
    carousel,
    title,
    isRoleRemote,
  } = pageContext;

  const isMobileWindow = useMatchMedia({ max: "L" });

  const origin = useSiteUrl();
  const jobPostUrl = createUrlPathFromArray([origin, pagePath]);

  const applicationPageLink = useMemo(() => {
    const slug = createUrlPathFromArray([pagePath, "apply"]);
    return createInternalLink(slug);
  }, [pagePath]);

  return (
    <TemplateGlobalEntry
      {...props}
      subNavData={subNavData}
      pagePath={pagePath}
      entryAssets={entryAssets}
      programmaticPageTitle={title}
    >
      <SVGBackgroundHeroWrapper hasWave background="careers_secondary">
        <Contain>
          <JobPostHeroSection
            {...{
              applicationPageLink,
              title,
              officeLocationCity,
              officeLocationCountry,
              salaryRange,
              teamName,
              isRoleRemote,
            }}
          />
        </Contain>
      </SVGBackgroundHeroWrapper>

      {isMobileWindow && (
        <JobPostStickyApplyButton
          {...{ applicationPageLink, jobPostUrl, title }}
        />
      )}

      <Contain>
        {/* `contentContainerStyle` creates a flex direction row on desktop, but on
        mobile it uses flex direction column instead — this stops the sticky nav
        from being sticky on mobile */}
        <div className={contentContainerStyle}>
          {!isMobileWindow && (
            <JobPostSidePanel
              {...{
                applicationPageLink,
                officeLocationCountry,
                officeLocationCity,
                salaryRange,
                teamName,
                title,
                jobPostUrl,
                isRoleRemote,
              }}
            />
          )}
          {/* Job post content */}
          <SanitizeHtmlWrapper
            wrapperClassName={jobDescriptionStyle}
            htmlString={content}
            data-testid="job-post-content"
          />
        </div>

        {/* ————— "TRUELAYER EXPERIENCE" CAROUSEL ————— */}
        {carousel && (
          <div className={carouselWrapperStyle}>
            <Heading
              visualLevel={2}
              seoLevel={3}
              isCentered
              margin={HEADING_MARGIN}
            >
              {CAROUSEL_HEADING}
            </Heading>
            <CarouselNew {...carousel} />
          </div>
        )}
      </Contain>

      {/* ————— LOCALISED, OFFICE SPECIFIC CONTENT ————— */}
      {localisedContent && (
        <JobPostLocalisedContent
          {...localisedContent}
          link={applicationPageLink}
        />
      )}

      {/* ————— RELATED JOBS / OPEN ROLES CTA ————— */}
      <Section sectionSettings={{ hasInnerPadding: true }} isStoryblok={false}>
        <JobPostRelatedJobsSection relatedJobsList={relatedJobsList} />
      </Section>
    </TemplateGlobalEntry>
  );
}

TemplateJobPost.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
    items: STORYBLOK_ENTRY_ARRAY,
    assets: PropTypes.arrayOf(MEDIA),
  }).isRequired,
  pageContext: PropTypes.shape({
    carousel: PropTypes.shape({}),
    content: PropTypes.string,
    isRoleRemote: PropTypes.bool,
    lang: PropTypes.string,
    localisedContent: PropTypes.shape({}),
    officeLocationCity: PropTypes.string,
    officeLocationCountry: PropTypes.string,
    pagePath: PropTypes.string,
    relatedJobsList: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        teamName: PropTypes.string,
        country: PropTypes.string,
        officeLocation: PropTypes.string,
        link: LINK,
      })
    ),
    salaryRange: PropTypes.shape({
      unit: PropTypes.string,
      min_value: PropTypes.string,
      max_value: PropTypes.string,
    }),
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
    teamName: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export const query = graphql`
  query jobPostPageQuery($id: String, $langRegex: String) {
    ...story
    ...global
  }
`;
