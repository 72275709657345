export function formatCurrencyToGbpShort(input) {
  let localNumber = input;

  if (typeof localNumber === "string") {
    localNumber = parseInt(localNumber, 10);
  }
  if (Math.abs(localNumber) >= 999999) {
    return `£${
      Math.sign(localNumber) * (Math.abs(localNumber) / 1000000).toFixed(2)
    }M`;
  }

  if (Math.abs(localNumber) >= 999) {
    return `£${
      Math.sign(localNumber) * (Math.abs(localNumber) / 1000).toFixed(1)
    }k`;
  }
  return `£${localNumber.toLocaleString("en-UK", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`;
}

export const formatCurrencyToGbpLong = (number) => {
  let localNumber = number;

  if (typeof localNumber === "string") {
    localNumber = parseInt(localNumber, 10);
  }
  return `£${localNumber.toLocaleString("en-UK", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`;
};

export function formatCurrencyToRangeGbpShort(input) {
  const lowerValue = input * 0.95;
  const higherValue = input * 1.05;

  return `${formatCurrencyToGbpShort(lowerValue)}—${formatCurrencyToGbpShort(
    higherValue
  )}`;
}

export function formatCurrencyToRangeGbpLong(input) {
  const lowerValue = input * 0.95;
  const higherValue = input * 1.05;

  return `${formatCurrencyToGbpLong(lowerValue)}—${formatCurrencyToGbpLong(
    higherValue
  )}`;
}

export function formatCurrencyString({
  currencyString,
  unitCode,
  countryCode,
}) {
  const countryCodeLowerCase = countryCode.toLowerCase();
  const countryCodeCapitalized = countryCode.toUpperCase();
  const languageCountryCode = `${countryCodeLowerCase}-${countryCodeCapitalized}`;

  return parseInt(currencyString, 10).toLocaleString(languageCountryCode, {
    style: "currency",
    currency: unitCode,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
}
